@import "../../styles/_chunk";

// New Design

.WishlistButton {
  display: flex;
  align-items: center;

  .WishlistButton__button {
    width: 35px;
    height: 35px;
    background: transparent;
    border: 1px solid $grey-10;
    border-radius: $border-radius-sm;
    padding: 0;
    margin: 0;
    transition: all 0.15s ease-in-out;
    font-size: 1.125rem; /* 18/16 */
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-white;

    &--active {
      color: $primary;
    }

    @include theme(light) {
      border: 1px solid map-get($light-theme, input-border-color);
      color: $grey-7;
    }
  }

  .WishlistButton__textContainer {
    font-size: 0.875rem; /* 14/16 */

    @include media-breakpoint-up(lg) {
      max-width: 0; // This is for the slide out transition
      transition: max-width 1.5s ease;
      background-color: $primary;
      color: $text-black;
      border-top-left-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
      font-weight: 400;
      font-size: 0.75rem; /* 12/16 */
      padding: 0 3px 0 4px;
    }

    .WishlistButton__text {
      margin-right: 0.5rem;

      @include media-breakpoint-up(lg) {
        white-space: nowrap;
        overflow: hidden;
        display: none;
        margin-right: 0;
      }
    }
  }

  &:hover {
    .WishlistButton__button {
      border-color: $primary;
      color: $primary;
    }

    .WishlistButton__textContainer {
      @include media-breakpoint-up(lg) {
        display: flex;
        width: auto;
        max-width: 400px;
      }

      .WishlistButton__text {
        display: block;
      }
    }
  }
}

.wishlist-btn {
  font-size: 0.75rem;
  padding: 0;
  display: flex;
  margin-top: 1px;
  align-items: center;
  color: $text-white;
  transition: color 0.15s ease-in-out;
  border: none;
  font-weight: 100;
  background: none;

  @include theme(light) {
    color: map-get($light-theme, font-color);
    font-weight: map-get($light-theme, font-weight-regular);
  }

  @include media-breakpoint-up(xl) {
    font-size: 0.8125rem;
  }

  svg {
    background-color: $grey-11;
    color: $grey-2;
    height: 22px !important;
    width: 22px !important;
    padding: 5px;
    border-radius: $border-radius-sm;
    margin-left: 8px;

    @include theme(light) {
      background-color: $grey-3;
      color: $text-black;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
    box-shadow: none;
    outline: none;
  }

  &:hover {
    color: $primary-core;

    button,
    svg {
      color: $primary-core;
    }
  }
}

.wishlist-btn-saved {
  svg {
    color: $primary-core !important;
  }
}
